
import React from 'react'
import Img from 'gatsby-image'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import { graphql } from 'gatsby'
import "../components/about.css"

const About = ({ data }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO title="About | Long" desc="Hi. I'm Long! Send me a DM and lets connect!" />
      <AnimatedBox style={pageAnimation}>
      <h1><strong>Impressum</strong></h1>
      <h4>Herausgeber, inhaltliche Verantwortlichkeit und rechtlicher Hinweise nach §5-§6 TMG (Telemediengesetz):</h4>
      <p>Long Pham</p>
      <p>Die Kennzeichnungspflicht von Internetseiten wird im Telemediengesetz (TMG) spezifiziert. Der Gesetzestext ist unter www.gesetze-im-internet.de einsehbar (veröffentlicht vom Bundesministerium der Justiz, Berlin).</p>
      <p>Lohmühlenstraße 65<br />12435 Berlin</p>
      <p>Kontakt:<br />E-Mail: long@phamilie.de<br />Website: long.phamilie.de</p>
      <p> </p>
      <p>Bei redaktionellen Inhalten:</p>
      <p>Verantwortlich nach § 55 Abs.2 RStV<br />Long Pham <br />Lohmühlenstraße 65<br />12435 Berlin</p>
        <div className="w-full container mx-auto text-white p-10 sm:p-1 md:p-4 lg:p-2 text-left text-justify break-words">
          <section className="w-full text-left">
          <h4 className="font-bold text-2xl pt-5">Urheberrechtshinweise</h4><p>Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt recherchiert und aktualisiert. Gleichwohl übernimmt der Anbieter keine Haftung für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Informationen. Haftungsansprüche gegen den Anbieter sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Die Informationen, die auf dieser Website bereitgestellt werden, sind allgemeiner Art und unverbindlich. Für verbindliche Auskunft nehmen Sie bitte direkten Kontakt mit dem Anbieter auf.</p><h4 className="font-bold text-2xl pt-5">Haftungshinweis für Verweise und Links</h4><p>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Der Anbieter erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Verlinkung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Inhalte der verlinkten Seiten, hat der Anbieter keinerlei Einfluss.</p><h4>Rechtswirksamkeit</h4><p>Sollten Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.</p></section></div>
      </AnimatedBox>
    </Layout>
  )
}

export default About
